import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form, Card, message, Divider, Space } from 'antd';
import {getAppEnvConfig} from './AppConfig'
import * as Apis from './Apis'

const Login: React.FC = () => {
  const [phoneNum, setPhoneNum] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isSmsCodeValid, setIsSmsCodeValid] = useState(false);
  const [isSmsCodeSendSuccess, setIsSmsCodeSendSuccess] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
  async function checkLoginStatus() {
    const loggedIn = await Apis.getUserInfo();
    if (loggedIn) {
      navigate('/');
    }
  }
  checkLoginStatus();
}, []);

  const validatePhone = (phone: string) => {
    const phoneRegex = /^1[3-9]\d{9}$/;
    return phoneRegex.test(phone);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phone = e.target.value;
    setPhoneNum(phone);
    setIsPhoneValid(validatePhone(phone));
  };

  const handleSmsCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    setSmsCode(code);
    setIsSmsCodeValid(code.length === 6);
  };

  const handleSubmit = async () => {
    try {
      await Apis.login(phoneNum, smsCode);
      message.info(`login success`)
      navigate('/');
    } catch (e) {
      console.error(e)
    }
  };

  // Create the sendSmsCode function
  const sendSmsCode = async () => {
    if (isPhoneValid) {
      try {
        await Apis.sendSmsCode(phoneNum)
        message.info('短信验证码发送成功，请注意查收短信')
        setIsSmsCodeSendSuccess(true);
      } catch (e) {
        console.error(e)
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', background: 'linear-gradient(#ff4fbd, #6248ff)' }}>
      <Card title=<Space>{getAppEnvConfig().appTitle}</Space> style={{ textAlign: 'center', borderRadius: 6 }}>
        <Form layout="vertical" onFinish={handleSubmit} size='large'>
          <Form.Item name='phoneNum' required>
            <Input value={phoneNum} onChange={handlePhoneChange} placeholder='手机号' />
          </Form.Item>
          <Form.Item name='smsCode' required>
            <Input.Group compact>
              <Input style={{ width: 'calc(100% - 90px)', textAlign: 'left' }} value={smsCode} placeholder='短信验证码'
              onChange={handleSmsCodeChange} />
              {isSmsCodeSendSuccess ? 
              <Button type="primary" disabled onClick={sendSmsCode} style={{width: '90px'}}>已发送</Button> :
                <Button type="primary" disabled={!isPhoneValid} onClick={sendSmsCode} style={{width: '90px'}}>发送</Button>
              }
          </Input.Group>
          </Form.Item>
          <Divider />
          <Form.Item>
            <Button type="primary" block htmlType="submit" disabled={!isPhoneValid || !isSmsCodeValid}>
              登陆
            </Button>
          </Form.Item>
        </Form>
      </Card>
      
    </div>
  );
};

export default Login;