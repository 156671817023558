import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ValueNet: React.FC<IProps> = (props: IProps) => {

    return <div>
        <Form.Item label='公司所在行业' name='industry'
            rules={[{ required: true, message: '请输入公司所在行业' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={100}
                showCount
                allowClear
                placeholder="请输入公司所在行业"
            ></Input.TextArea>
        </Form.Item>
        <Form.Item label='公司核心业务' name='biz'
            rules={[{ required: true, message: '请输入公司核心业务' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxLength={300}
                showCount
                allowClear
                placeholder="请输入公司核心业务"
            ></Input.TextArea>
        </Form.Item>
        <Form.Item label='公司基础信息' name='basicInfo'
            rules={[{ required: true, message: '请输入公司基础信息' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxLength={300}
                showCount
                allowClear
                placeholder="请输入公司基础信息，信息越详尽，生成的结果会越精准"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default ValueNet;