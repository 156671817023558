import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Avatar, Dropdown, Menu, MenuProps } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import {getAppEnvConfig} from './AppConfig';

const UserInfo: React.FC = () => {
  const navigate = useNavigate();

  const logout = () => {
    // Clear token from local storage
    localStorage.removeItem(getAppEnvConfig().tokenKey);
    navigate('login');
  };

  const items: MenuProps['items'] = [{
    key: '1',
    label: '退出',
    icon: <LogoutOutlined />
  }]

  const onMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '1') {
      logout();
    }
  }


  return (
<div style={{marginRight: 20, marginLeft: 20}}>
<Dropdown menu={{items, onClick: onMenuClick, theme: 'light'}} trigger={['click']} overlayStyle={{width: 120}}>
  <Avatar style={{ cursor: 'pointer' }} icon={<UserOutlined />} size={32}></Avatar>
</Dropdown>
</div>
  );
};

export default UserInfo;