import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ArticleOutline: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='文章主题' name='articleTopic'
            rules={[{ required: true, message: '请输入文章主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入文章主题"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='核心观点' name='view'
            rules={[{ required: true, message: '请输入文章的核心观点' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入文章的核心观点"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='文章受众' name='audience'
            rules={[{ required: true, message: '请输入文章受众' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入文字受众"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='文章类型' name='category'
            rules={[{ required: true, message: '请输入文章类型' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入文章类型，如网络专栏、学术论文等"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default ArticleOutline;