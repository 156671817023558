import { Form, Input, Radio } from "antd";
import React from "react";

interface IProps {
}

const EventPlan: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='活动主题' name='topic'
            rules={[{ required: true, message: '请输入活动主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入活动主题"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default EventPlan;