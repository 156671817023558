import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const OfficeEmail: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='收件人' name='receiver'
            rules={[{ required: true, message: '请输入收件人' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={20}
                showCount
                allowClear
                placeholder="请输入收件人"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='邮件主题' name='topic'
            rules={[{ required: true, message: '请输入邮件主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={100}
                showCount
                allowClear
                placeholder="请输入邮件主题"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='邮件风格' name='style'
            rules={[{ required: true, message: '请输入邮件内容的风格' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={50}
                showCount
                allowClear
                placeholder="请输入邮件内容的风格"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default OfficeEmail;