import { Drawer, Col, Space, Button, Form, Modal } from 'antd';
import React, { useState, CSSProperties } from 'react';
import { isMobile } from 'react-device-detect';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import * as ChatAppForm from './ChatApps';
import { ChatApp, generatePromptForChatApp, ChatAppId } from './Data';
import { createNewChat, ChatInterface, AppScene } from './Apis';
import {getAppEnvConfig} from './AppConfig';
import './App.css';

interface IProps {
    app: ChatApp
}

const ChatAppContainer: React.FC<IProps> = (props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [completion, setCompletion] = useState<string>('');

    const [form] = Form.useForm();

    const renderApp = () => {
        switch (props.app.appId) {
            case ChatAppId.TiktokVideoScript:
                return <ChatAppForm.TiktokVideoScript />
            case ChatAppId.TiktokVideoIdea:
                return <ChatAppForm.TiktokVideIdea />
            case ChatAppId.TiktokVideoBroadcast:
                return <ChatAppForm.TiktokVideoBroadcast />
            case ChatAppId.ArticleIdea:
                return <ChatAppForm.ArticleIdea />
            case ChatAppId.ArticleOutline:
                return <ChatAppForm.ArticleOutline />
            case ChatAppId.ContentRewriting:
                return <ChatAppForm.ContentRewriting />
            case ChatAppId.HSSTitle:
                return <ChatAppForm.XHSNoteTitle />
            case ChatAppId.HHSCopywritting:
                return <ChatAppForm.XHSCopywritting />
            case ChatAppId.ProductIntro:
                return <ChatAppForm.ProductIntro />
            case ChatAppId.ProductSellingPoint:
                return <ChatAppForm.ProductSellingPoint />
            case ChatAppId.OfficeEmail:
                return <ChatAppForm.OfficeEmail />
            case ChatAppId.Translation:
                return <ChatAppForm.Translation />
            case ChatAppId.AdvertisingCreativity:
                return <ChatAppForm.AdvertisingCreativity />
            case ChatAppId.EventPlan:
                return <ChatAppForm.EventPlan />
            case ChatAppId.ArticleWrite:
                return <ChatAppForm.ArticleWrite />
            case ChatAppId.FiveForces:
                return <ChatAppForm.FiveForces />
            case ChatAppId.SWOT:
                return <ChatAppForm.SWOT />
            case ChatAppId.ValueNet:
                return <ChatAppForm.ValueNet />
            case ChatAppId.ContentSummary:
                return <ChatAppForm.ContentSummary />
            case ChatAppId.CompanySlogan:
                return <ChatAppForm.CompanySlogan />
            default:
                return <>not support</>
        }
    }

    const initFormVals = () => {
        return {}
    }

    const onSubmit = async () => {
        setLoading(true);
        const prompt = generatePromptForChatApp(props.app.appId, form.getFieldsValue())
        console.log(`prompt:`, prompt);
        try {
            if (prompt) {
                const response = await createNewChat(prompt, AppScene.App);
                console.log(`res:`, response);
                if (response && (response as ChatInterface).completion) {
                    setCompletion((response as ChatInterface).completion)
                } else {
                    throw new Error(`生成内容失败，请重新再试`);
                }
            } else {
                throw new Error(`你还没有输入指令`);
            }
        } catch (e) {
            // message.error(`${e}`)
            if (e === `40002`) {
                Modal.error({
                    title: '过期提醒',
                    content: <div>
                        <p>你的使用时间已过期！你可以扫码加入用户交流群，群内会不定期发放激活码。</p>
                        <p>使用激活码，可以延长使用期限。如你已获的激活码，可在<span style={{fontWeight: 'bold'}}>“信息统计”</span>页面进行使用。</p>
                        <img src={getAppEnvConfig().kfQrCode} alt="kefu" style={{ width: '260px' }} />
                    </div>,
                    okText: '确定',
                    okButtonProps: {
                        block: true,
                    },
                })
            }
        } finally {
            setLoading(false);
        }
    }

    const renderCompletion = (completion: string) => {
        // const data = completion.split('\n');
        return <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || '');
                    console.log(`match:`, match)
                    return !inline && match ? (
                        <pre
                            style={{ backgroundColor: 'black', padding: '16px', borderRadius: '8px' }}
                            {...props}
                        >
                            <code className={`language-${match[1]}`} style={{ color: 'white' }}>
                                {children}
                            </code>
                        </pre>
                    ) : (
                        <code className={className} {...props}>{children}</code>
                    );
                },
            }}
        >
            {completion}
        </ReactMarkdown>
    }

    const renderFormForPC = () => {
        return <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '50%', backgroundColor: '#f3f5f8', overflowY: 'auto', padding: '20px', height: 'calc(100vh - 65px)' }}>
                <Form
                    layout={'vertical'}
                    form={form}
                    initialValues={initFormVals()}
                    size={'large'}
                    style={{ width: '100%' }}
                    onFinish={onSubmit}
                >

                    {renderApp()}

                    <Form.Item style={{ marginTop: '46px', display: 'flex', justifyContent: 'center' }}>
                        <Button type='primary' htmlType='submit' style={{ width: '180px' }} loading={loading}>提交</Button>
                    </Form.Item>

                </Form>
            </div>
            <div style={{ width: '50%', padding: '20px', overflowY: 'auto' }}>
                {completion && renderCompletion(completion)}
            </div>
        </div>
    }

    const renderFormForMobile = () => {
        return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div className="chatapp-left-container-mobile">
                <Form
                    layout={'vertical'}
                    form={form}
                    initialValues={initFormVals()}
                    size={'large'}
                    onFinish={onSubmit}
                >

                    {renderApp()}

                    <Form.Item>
                        <Button type='primary' htmlType='submit' block loading={loading}>提交</Button>
                    </Form.Item>

                </Form>
            </div>
            <div className='chatapp-right-container-mobile'>
                {completion && renderCompletion(completion)}
            </div>
        </div>
    }

    const closeDrawer = () => {
        setIsOpen(false);
        setLoading(false);
        setCompletion('');
        form.resetFields();
    }

    const appCardStyle: CSSProperties = {
        minHeight: isMobile ? '180px' : '160px',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '8px',
        cursor: 'pointer',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    }

    return (
        <>
            <Col key={props.app.appId}
                xs={12}
                sm={8}
                md={8}
                lg={6}
                xl={6}
                xxl={4}
            >
                <div
                    style={appCardStyle}
                    onClick={() => setIsOpen(true)}
                >
                    <div style={{height: '40px', display: 'flex', alignItems: 'center'}}>
                        <div style={{ fontSize: '1.6em' }}>{props.app.icon}</div>
                    </div>
                    <div style={{ color: 'black', fontSize: '1.1em', fontWeight: 'bold', marginTop: '8px' }}>{props.app.title}</div>
                    <div style={{ marginTop: '8px', color: 'grey' }}>{props.app.desc || ''}</div>
                </div>

            </Col>
            <Drawer
                open={isOpen}
                width={'100vw'}
                height={'100%'}
                placement={'bottom'}
                title={props.app.title}
                closable={true}
                onClose={closeDrawer}
                bodyStyle={{ padding: 0, height: 'calc(100vh - 64px)', overflowY: 'auto' }}
                extra={
                    <Space>
                        <Button style={{ borderRadius: '4px' }} onClick={e => closeDrawer()}>关闭</Button>
                    </Space>
                }
            >
                {isMobile ? renderFormForMobile() : renderFormForPC()}
            </Drawer>
        </>
    );
};

export default ChatAppContainer;