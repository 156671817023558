import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ProductSellingPoint: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='产品名称' name='productName'
            rules={[{ required: true, message: '请输入产品名称' }]}
        >
            <Input
                placeholder="请输入产品名称"
            ></Input>
        </Form.Item>

        <Form.Item label='产品信息' name='productDesc'
            rules={[{ required: true, message: '请输入产品信息' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                maxLength={500}
                showCount
                allowClear
                placeholder="请输入产品信息"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default ProductSellingPoint;