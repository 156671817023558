import React, { useState } from 'react';
import { Layout, Button, Row, Col, Card, Space } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { getAppEnvConfig } from './AppConfig';
import UserInfo from './UserInfo';
import { chatApps, getChatAppsByCategoryId, CategoryId } from './Data';
import ChatAppContainer from './ChatAppContainer';
import './App.css';

const { Header, Content } = Layout;

const AppStore: React.FC = () => {

    const navigate = useNavigate();

    const [selectedCategory, SetSelectedCategory] = useState<CategoryId>(CategoryId.All);

    const renderChatAppsCard = () => {
        return getChatAppsByCategoryId(selectedCategory).map(app => {
            return <ChatAppContainer key={app.appId} app={app} />
        })
    }

    return (
        <Layout style={{ height: '100vh' }}>
            <Header style={{ background: 'black', padding: 0, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', display: 'flex', alignItems: 'center' }}>

                <div style={{ padding: '0 16px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/')}>
                    {/*   <img src={logo} alt="Brand Logo" style={{height: '32px', marginRight: '16px'}} /> */}
                    <span style={{ color: 'white', fontSize: '20px', marginRight: '16px' }}>{getAppEnvConfig().appTitle}</span>
                </div>

                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {isMobile ?
                        <Button type="text" onClick={() => navigate('/')} style={{ color: 'white', width: 46 }} icon={<MessageOutlined style={{ color: 'white' }} />} /> :
                        <Button type="text" onClick={() => navigate('/')} style={{ color: 'white' }} icon={<MessageOutlined style={{ color: 'white' }} />}>
                            聊天
                        </Button>
                    }
                    <UserInfo />
                </div>

            </Header>
            <Content style={{ margin: '0', height: 'calc(100vh - 64)', padding: '0px 16px', overflowY: 'auto' }}>
                <div style={{ padding: '16px 0px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Space wrap>
                        <Button shape="round" style={{ minWidth: isMobile ? '80px' : '90px' }} onClick={() => SetSelectedCategory(CategoryId.All)} type={selectedCategory === CategoryId.All ? "primary" : "default"}>全部</Button>
                        <Button shape="round" style={{ minWidth: isMobile ? '80px' : '90px' }} onClick={() => SetSelectedCategory(CategoryId.Promotion)} type={selectedCategory === CategoryId.Promotion ? "primary" : "default"}>营销</Button>
                        <Button shape="round" style={{ minWidth: isMobile ? '80px' : '90px' }} onClick={() => SetSelectedCategory(CategoryId.Office)} type={selectedCategory === CategoryId.Office ? "primary" : "default"}>办公</Button>
                        <Button shape="round" style={{ minWidth: isMobile ? '80px' : '90px' }} onClick={() => SetSelectedCategory(CategoryId.Write)} type={selectedCategory === CategoryId.Write ? "primary" : "default"}>写作</Button>
                        <Button shape="round" style={{ minWidth: isMobile ? '80px' : '90px' }} onClick={() => SetSelectedCategory(CategoryId.MBA)} type={selectedCategory === CategoryId.MBA ? "primary" : "default"}>商业分析</Button>
                    </Space>
                </div>

                <Row gutter={[20, 20]} style={{ marginBottom: '20px' }}>
                    {renderChatAppsCard()}
                </Row>
            </Content>
        </Layout>
    );
};

export default AppStore;