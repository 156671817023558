import React, { useState, useEffect } from 'react';
import { Button, Input, Divider, Form, Select, message, Table, Card, Row, Col } from 'antd';
import { adminQueryUserInfoByPhoneNum, AdminQueryUserResp, formatSubscribeVersionToTitle, SubscribeVersion, adminCreateOfflineRechargeOrder } from '../Apis'
import moment from 'moment'

const { Option } = Select;

const UserManager: React.FC = () => {

  const [userInfo, setUserInfo] = useState<AdminQueryUserResp>();
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [subscribeVersion, setSubscribeVersion] = useState<SubscribeVersion>(SubscribeVersion.SubscribeByYear);
  const [amount, setAmount] = useState<number>(349.9);

  const queryUserBy = async (phone: string) => {
    if (phone) {
      const res = await adminQueryUserInfoByPhoneNum(phone)
      if (res) {
        console.log(`user:`, res)
        setUserInfo(res)
      } else {
        message.error(`查询用户失败，请重试`)
      }
    } else {
      message.warn(`请输入用户手机号`)
    }
  }

  const onSubscribePlanChange = (plan: SubscribeVersion) => {
    if (plan) {
      setSubscribeVersion(plan)
      if (plan === SubscribeVersion.SubscribeByMonth) {
        setAmount(44.9)
      } else if (plan === SubscribeVersion.SubscribeByQuarter) {
        setAmount(149.9)
      } else if (plan === SubscribeVersion.SubscribeByYear) {
        setAmount(349.9)
      } else {
        setAmount(0)
      }
    }
  }

  const onAmountChange = (a: number) => {
    setAmount(a)
  }

  const onSubmit = async () => {
    if (phoneNum && subscribeVersion && amount) {
      const res = await adminCreateOfflineRechargeOrder(
        phoneNum,
        subscribeVersion,
        amount * 100);

      if (res) {
        message.success(`添加线下订单成功`)
        queryUserBy(phoneNum)
      } else {
        message.error(`添加线下订单失败，请重试`)
      }

    } else {
      message.warn(`参数错误`)
    }
  }

  return (
    <Row style={{ width: '100%', height: '100%' }}>
      <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}>
        <Input.Group compact>
          <Input style={{ width: '300px' }} placeholder='输入用户的手机号' onChange={e => setPhoneNum(e.target.value)} value={phoneNum} />
          <Button type='primary' style={{ width: '100px' }} onClick={() => queryUserBy(phoneNum)}>搜索</Button>
        </Input.Group>
        <Divider />
      </Col>


      {userInfo &&
        <>
          <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}>
            <Card title='用户信息' headStyle={{ backgroundColor: 'grey', color: 'white' }} size="small">
              <div>
                <p>手机号：{userInfo.user.phoneNum}</p>
                <p>用户版本：{formatSubscribeVersionToTitle(userInfo.user.subscribeVersion)}</p>
                <p>到期日：{moment(userInfo.user.expireAt).format('YYYY-MM-DD')}</p>
                <p>每日可用次数：{userInfo.user.numOfDay} 次</p>
              </div>
            </Card>
          </Col>

          <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}>
            <Card title='订单记录' style={{ marginTop: '30px' }} headStyle={{ backgroundColor: 'grey', color: 'white' }} size="small">
              <Table dataSource={userInfo.orders} rowKey='id' pagination={false} size="small">
                <Table.Column title='订单号' dataIndex='tradeNo' />
                <Table.Column title='卡类型' dataIndex='product' render={t => formatSubscribeVersionToTitle(t as number)} />
                <Table.Column title='金额' dataIndex='payAmount' render={v => v / 100} />
                <Table.Column title='创建时间' dataIndex='createdAt' render={time => moment(time).format('YYYY-MM-DD HH:mm:ss')} />
              </Table>
            </Card>
          </Col>

          <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}>
            <Card title='添加线下收款订单' style={{ marginTop: '30px' }} headStyle={{ backgroundColor: '#efb680', color: 'white' }} size="small">
              <Form layout='vertical' initialValues={{ subscribeVersion: SubscribeVersion.SubscribeByYear, amount: 349.9 }}>
                <Form.Item label='卡类型' name='subscribeVersion'>
                  <Select value={subscribeVersion} onChange={(e) => onSubscribePlanChange(e)}>
                    <Option value={SubscribeVersion.SubscribeByMonth}>月卡</Option>
                    <Option value={SubscribeVersion.SubscribeByQuarter}>季卡</Option>
                    <Option value={SubscribeVersion.SubscribeByYear}>年卡</Option>
                    <Option value={SubscribeVersion.FreeTrial}>免费体验卡</Option>
                  </Select>
                </Form.Item>
                {/*         <Form.Item label='金额（单位：元）' name='amount'>
          <Input placeholder='输入金额' value={amount} onChange={e => onAmountChange(parseInt(e.target.value))} />
        </Form.Item> */}
                <Form.Item>
                  <Button type='primary' style={{ width: '160px' }} onClick={() => onSubmit()}>添加线下订单</Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </>
      }

    </Row>
  );
};

export default UserManager;