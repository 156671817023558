import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const TiktokVideoScript: React.FC<IProps> = (props: IProps) => {

    return <div>
        <Form.Item label='拍摄主题' name='target'
            rules={[{ required: true, message: '请输入拍摄主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入拍摄主题"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='关键词' name='keywords'
            rules={[{ required: true, message: '请输入内容关键词' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入内容关键词"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default TiktokVideoScript;