import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import UserManager from './adminComponents/UserManager'
import { isMobile } from 'react-device-detect'

const Admin: React.FC = () => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      {isMobile ?
        <Button type='text' style={{ color: 'white', width: 46 }} icon={<SettingFilled />} onClick={() => setVisible(true)} /> :
        <Button type='text' style={{ color: 'white' }} icon={<SettingFilled />} onClick={() => setVisible(true)}>管理</Button>
      }
      <Drawer
        title="管理端"
        placement="right"
        closable={true}
        open={visible}
        key="admin-console"
        width={'100vw'}
        onClose={() => setVisible(false)}
      >
        <UserManager />

      </Drawer>

    </>
  )
};

export default Admin;
