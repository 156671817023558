import React from 'react';
import { Button } from 'antd';
import {TagsOutlined} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import {useNavigate} from 'react-router-dom';

const NFTLabsButton = () => {

    const toAppStorePage = () => {
        window.open('https://www.nftnetlabs.com/', '_blank');
    }

    return (
        <>
            {isMobile ?
                <Button type="text" onClick={toAppStorePage} style={{ color: 'white', width: 46 }} icon={<TagsOutlined style={{ color: 'white' }} />} /> :
                <Button type="text" onClick={toAppStorePage} style={{ color: 'white' }} icon={<TagsOutlined style={{ color: 'white' }} />}>
                    NFTNETLabs
                </Button>
            }
        </>
    );
};

export default NFTLabsButton;