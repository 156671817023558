import React, { useState } from 'react';
import { Modal, Steps, Button, Card, Row, Col, Drawer, Space, message } from 'antd';
import { CheckCircleTwoTone, MoneyCollectOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import * as Apis from './Apis'

const PaymentMenu: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Create a state called currentStep
  const [currentStep, setCurrentStep] = useState(0);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const submitToPay = async () => {
    let plan: Apis.SubscribeVersion = Apis.SubscribeVersion.FreeTrial;
    if (currentStep === 0) plan = Apis.SubscribeVersion.SubscribeByMonth;
    if (currentStep === 1) plan = Apis.SubscribeVersion.SubscribeByQuarter;
    if (currentStep === 2) plan = Apis.SubscribeVersion.SubscribeByYear;
    try {
      const resp = await Apis.toPay(plan);
      // window.open(resp, '_blank');
      Modal.info({
        title: '操作提醒',
        content: <p>当你支付成功后，请刷新本页面以查看最新状态</p>,
        okText: '我已了解',
        onOk: () => {
          // window.location.reload();
          window.open(resp, '_blank');
        },
      })
    } catch (e) {
      message.error(`发起支付失败，请重试`)
      console.log(`invoke pay err:`, e)
    }
  }

  return (
    <>
      {isMobile ?
        <Button type="text" onClick={showModal} style={{ color: 'white', width: 46 }} icon={<MoneyCollectOutlined style={{ color: 'white' }} />} /> :
        <Button type="text" onClick={showModal} style={{ color: 'white' }} icon={<MoneyCollectOutlined style={{ color: 'white' }} />}>
          续费
        </Button>
      }
      <Drawer
        title="续费"
        open={isModalVisible}
        onClose={handleCancel}
        bodyStyle={{ overflowY: 'auto', padding: 20 }}
      >
        <>
          <Space direction='vertical' style={{ display: 'flex' }} size={'small'}>
            <Card
              style={{ borderColor: currentStep === 0 ? '#1890ff' : '#f0f0f0' }}
              onClick={() => setCurrentStep(0)}
              headStyle={{ padding: '0 12px' }}
              bodyStyle={{ padding: '12px' }}
              hoverable
              title={(
                <>
                  <span style={{ float: 'left' }}>月卡</span>
                  <span style={{ float: 'right' }}>
                    {currentStep === 0 && <CheckCircleTwoTone />}
                  </span>
                </>
              )}
            >
              <p>费用：44.9 元</p>
            </Card>

            <Card
              style={{ borderColor: currentStep === 1 ? '#1890ff' : '#f0f0f0' }}
              onClick={() => setCurrentStep(1)}
              headStyle={{ padding: '0 12px' }}
              bodyStyle={{ padding: '12px' }}
              hoverable
              title={(
                <>
                  <span style={{ float: 'left' }}>季度卡</span>
                  <span style={{ float: 'right' }}>
                    {currentStep === 1 && <CheckCircleTwoTone />}
                  </span>
                </>
              )}
            >
              <p>费用：149.9 元</p>
            </Card>

            <Card
              style={{ borderColor: currentStep === 2 ? '#1890ff' : '#f0f0f0' }}
              onClick={() => setCurrentStep(2)}
              headStyle={{ padding: '0 12px' }}
              bodyStyle={{ padding: '12px' }}
              hoverable
              title={(
                <>
                  <span style={{ float: 'left' }}>年卡</span>
                  <span style={{ float: 'right' }}>
                    {currentStep === 2 && <CheckCircleTwoTone />}
                  </span>
                </>
              )}
            >
              <p>费用：349.9 元</p>
            </Card>
          </Space>

          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
            <Button type="primary" onClick={e => submitToPay()} size={'middle'} block>支付</Button>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default PaymentMenu;