import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ArticleIdea: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='写作主题' name='topic'
            rules={[{ required: true, message: '请输入文章主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入文章主题"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='文章受众' name='audience'
            rules={[{ required: true, message: '请输入文章受众' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={100}
                showCount
                allowClear
                placeholder="文章的受众群体"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default ArticleIdea;