import 'core-js/es/map';
import 'core-js/es/set';

import React from 'react';
import ReactDOM from 'react-dom/client';
import {createHashRouter, RouterProvider} from 'react-router-dom';
import './index.css';
import App from './App';
import Login from './Login';
import AppStore from './AppStore';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const route = createHashRouter([
  {
    path: "/",
    element: <App />,
  }, {
    path: 'login',
    element: <Login />,
  }, {
    path: 'apps',
    element: <AppStore />,
  }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={route} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
