import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const Translation: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='待翻译的内容' name='content'
            rules={[{ required: true, message: '请输入需要翻译的内容' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                maxLength={1000}
                showCount
                allowClear
                placeholder="请输入需要翻译的内容"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='目标语言' name='toLanguage'
            rules={[{ required: true, message: '请输入目标语言' }]}
        >
            <Input
                maxLength={20}
                allowClear
                placeholder="请输入需要成为什么语言"
            ></Input>


        </Form.Item>
    </div>
}

export default Translation;