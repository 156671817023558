import axios from 'axios';
import {getAppEnvConfig} from './AppConfig'

export interface ConversationInterface {
    title: string
    id: number
}

export interface ChatInterface {
    id: number
    conversationId: number
    prompt: string
    completion: string
    newConversation?: ConversationInterface
}

export enum SubscribeVersion {
    FreeTrial = 1,
    SubscribeByMonth,
    SubscribeByQuarter,
    SubscribeByYear,
}

export interface UsageStatistic {
    version: SubscribeVersion,
    expireAt: string,
    todayLeft: number,
    isExpired: boolean,
    isReachLimited: boolean,
}

export enum AppScene {
    Chat = 1,
    App = 2,
}

export const formatSubscribeVersionToTitle = (v: SubscribeVersion) => {
    switch (v) {
        case SubscribeVersion.FreeTrial:
            return '体验版';
        case SubscribeVersion.SubscribeByMonth:
            return '月卡';
        case SubscribeVersion.SubscribeByQuarter:
            return '季度卡';
        case SubscribeVersion.SubscribeByYear:
            return '年卡';
        default:
            return `未知`;
    }
}

export interface UserInfoResp {
    user: {
        id: number,
        phoneNum: string,
        isActive: boolean,
        createdAt: Date,
    },
    usage: UsageStatistic
}

export interface AdminQueryUserResp {
    user: {
        id: number,
        phoneNum: string,
        isActive: boolean,
        subscribeVersion: SubscribeVersion,
        expireAt: Date,
        numOfDay: number,
        createdAt: Date,
    },
    orders: []
}

// Function to check if user is logged in or not
export async function getUserInfo(): Promise<UserInfoResp | null> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return false
    if (!token) {
        return null;
    }

    console.log(`load token from storage:`, token)

    // Call server API using axios
    try {
        const response = await axios.get(`${getAppEnvConfig().apiHost}/api/user/info?token=${token}`);
        console.log(`get user info by token:`, response)

        // If the response is successful, return true
        if (response.status === 200 && response.data.code === 0) {
            return response.data.data as UserInfoResp;
        } else {
            localStorage.removeItem(getAppEnvConfig().tokenKey);
            return null;
        }
    } catch (error) {
        // If an error occurs, return false
        // Clear token from browser storage
        localStorage.removeItem(getAppEnvConfig().tokenKey);
        return null;
    }
}

// Create a login function that accepts phoneNum and smsCode as parameters
export async function login(phoneNum: string, smsCode: string): Promise<string | null> {
    try {
        // Call the API with phoneNum and smsCode as parameters
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/user/login/phoneNumAndSmsCode`, {
            phoneNum,
            smsCode,
        });

        console.log(`login res:`, response);

        // If the response is successful and contains a token, save it to browser storage and return the token
        if (response.status === 200 && response.data.code === 0 && response.data.data.token) {

            localStorage.setItem(getAppEnvConfig().tokenKey, response.data.data.token);
            return response.data.data.token;
        }
    } catch (error) {
        // If an error occurs, return null
        return null;
    }

    // If the function reaches this point, return null
    return null;
}

// Create a function to send smsCode
export async function sendSmsCode(phoneNum: string): Promise<boolean> {
    try {
        // Call the API with phoneNum as parameter
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/user/smscode`, {
            phoneNum,
        });

        // If the response is successful, return true
        if (response.status === 200 && response.data.code === 1) {
            return true;
        }
    } catch (error) {
        // If an error occurs, return false
        return false;
    }

    // If the function reaches this point, return false
    return false;
}

// Function to fetch conversations
export async function fetchConversations(): Promise<ConversationInterface[]> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return an empty array
    if (!token) {
        return [];
    }

    try {
        // Call server API using axios to fetch conversations
        const response = await axios.get(`${getAppEnvConfig().apiHost}/api/conversation/list?token=${token}`);
        console.log(`conversations:`, response)

        // If the response is successful and contains data, return the data
        if (response.status === 200 && response.data.code === 0 && response.data.data) {
            return response.data.data.conversations as ConversationInterface[];
        }
    } catch (error) {
        // If an error occurs, return an empty array
        return [];
    }

    // If the function reaches this point, return an empty array
    return [];
}

// Function to fetch conversation detail
export async function fetchConversationDetail(conversationId: number): Promise<ChatInterface[] | null> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return null
    if (!token) {
        return null;
    }

    try {
        // Call server API using axios to fetch conversation detail
        const response = await axios.get(`${getAppEnvConfig().apiHost}/api/conversation/${conversationId}?token=${token}`);
        console.log(`fetch conversation:`, response)

        // If the response is successful and contains data, return the data
        if (response.status === 200 && response.data.code === 0 && response.data.data) {
            return response.data.data.chats as ChatInterface[];
        }
    } catch (error) {
        // If an error occurs, return null
        return null;
    }

    // If the function reaches this point, return null
    return null;
}

// Function to create a new conversation
export async function createNewConversation(title: string): Promise<ConversationInterface | null> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return null
    if (!token) {
        return null;
    }

    try {
        // Call server API using axios to create a new conversation
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/conversation/create?token=${token}`, {
            title,
        });

        console.log(`new conversation:`, response)

        // If the response is successful and contains data, return the data
        if (response.status === 200 && response.data.code === 0 && response.data.data) {
            return response.data.data.conversation as ConversationInterface;
        }
    } catch (error) {
        // If an error occurs, return null
        return null;
    }

    // If the function reaches this point, return null
    return null;
}

// Function to create a new chat
export async function createNewChat(prompt: string, fromAppScene: AppScene, conversationId?: number, ): Promise<ChatInterface | Error> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return null
    if (!token) {
        return Promise.reject(`no login`)
    }

    try {
        // Call server API using axios to create a new chat
        let bodyData = {prompt, fromAppScene}
        if (conversationId && conversationId !== 0) {
            bodyData = Object.assign({}, bodyData, {conversationId})
        }

        console.log(`create chat params:`, bodyData)
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/chat/create?token=${token}`, bodyData);

        console.log(`new chat call api:`, conversationId, prompt, response)

        // If the response is successful and contains data, return the data
        if (response.status === 200 && response.data.code === 0 && response.data.data) {
            return response.data.data as ChatInterface;
        }

        if (response.status === 200 && response.data.code === 40001) {
            return Promise.reject(`40001`)
        }

        if (response.status === 200 && response.data.code === 40002) {
            return Promise.reject(`40002`)
        }
    } catch (error) {
        // If an error occurs, return null
        return Promise.reject(error);
    }

    // If the function reaches this point, return null
    return Promise.reject(`call api err`);
}

// Function to delete a conversation
export async function deleteConversation(conversationId: number): Promise<boolean> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return false
    if (!token) {
        return false;
    }

    try {
        // Call server API using axios to delete a conversation
        const response = await axios.delete(`${getAppEnvConfig().apiHost}/api/conversation/${conversationId}?token=${token}`);

        // If the response is successful, return true
        if (response.status === 200 && response.data.code === 0) {
            return true;
        }
    } catch (error) {
        // If an error occurs, return false
        return false;
    }

    // If the function reaches this point, return false
    return false;
}

// Function to query user info by phoneNum
export async function adminQueryUserInfoByPhoneNum(phoneNum: string): Promise<AdminQueryUserResp | null> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return null
    if (!token) {
        return null;
    }

    try {
        // Call server API using axios to query user info by phoneNum
        const response = await axios.get(`${getAppEnvConfig().apiHost}/api/admin/user/q?phoneNum=${phoneNum}&token=${token}`);
        console.log(`query user info by phoneNum:`, response)

        // If the response is successful and contains data, return the data
        if (response.status === 200 && response.data.code === 0 && response.data.data) {
            return response.data.data as AdminQueryUserResp;
        }
    } catch (error) {
        // If an error occurs, return null
        return null;
    }

    // If the function reaches this point, return null
    return null;
}

export interface OfflineRechargeOrder {
    userId: number,
    amount: number,
    createdAt: Date,
}

export async function adminCreateOfflineRechargeOrder(phoneNum: string, subscribePlan: number, amount: number): Promise<boolean> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return false
    if (!token) {
        return false;
    }

    try {
        // Call server API using axios to create an offline recharge order
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/admin/order/offline?token=${token}`, {
            phoneNum,
            subscribePlan,
            amount,
        });

        // If the response is successful, return true
        if (response.status === 200 && response.data.code === 0) {
            return true;
        }
    } catch (error) {
        // If an error occurs, return false
        return false;
    }

    // If the function reaches this point, return false
    return false;
}

export async function consumeActiveCode(code: string): Promise<boolean> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return null
    if (!token) {
        return Promise.reject(`用户未登录`);
    }

    try {
        // Call server API using axios to create a new conversation
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/user/activeCode?token=${token}`, {
            code,
        });

        console.log(`use active code resp:`, response)

        if (response.status === 200) {
            if (response.data.code === 0) {
                return true;
            } else {
                throw new Error(`激活码无效`)
            }
        } else {
            throw new Error(`出错了，请重试`)
        }
    } catch (error) {
        // If an error occurs, return null
        return Promise.reject(error);
    }
}

// Call server toPay api, post, param is a number
export async function toPay(num: SubscribeVersion): Promise<string> {
    // Load token from browser storage
    const token = localStorage.getItem(getAppEnvConfig().tokenKey);

    // If token is not found, return false
    if (!token) {
        return Promise.reject(`用户未登录`);
    }

    try {
        // Call server API using axios to pay
        const response = await axios.post(`${getAppEnvConfig().apiHost}/api/topay?token=${token}`, {
            subscribePlan: num,
        });

        console.log(`pay result:`, response.data)

        // If the response is successful, return true
        if (response.status === 200 && response.data.code === 0) {
            return response.data.data;
        }
    } catch (error) {
        // If an error occurs, return false
        return Promise.reject(error);
    }

    return Promise.reject(new Error(`unknown`))
}