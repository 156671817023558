import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const XHSCopywritting: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='商品名称' name='productName'
            rules={[{ required: true, message: '请输入商品名称' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={100}
                showCount
                allowClear
                placeholder="请输入商品名称"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='商品特点' name='productFeatures'
            rules={[{ required: true, message: '请输入商品特点' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 8 }}
                maxLength={300}
                showCount
                allowClear
                placeholder="请输入商品特点"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default XHSCopywritting;