import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ContentSummary: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='原文内容' name='content'
            rules={[{ required: true, message: '请输入需要概括的内容' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 6, maxRows: 12 }}
                maxLength={2000}
                showCount
                allowClear
                placeholder="请输入需要总结概括的原文内容"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='要求' name='requirement'>
            <Input
                maxLength={100}
                allowClear
                placeholder="请输入概括的要求，如：结果以列表形式输出，内容要通俗易懂等"
            ></Input>
        </Form.Item>
    </div>
}

export default ContentSummary;