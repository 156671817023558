/**
 * app config
 */

export enum EnvMode {
    dev='dev',
    prod='prod',
}

export enum PlatformMode {
    GPTCN='gptcn',
}

export interface AppEnvConfig {
    appTitle: string,
    tokenKey: string,
    apiHost: string,
    kfQrCode: string,
}

const env = 'prod';
const platform = 'gptcn';

export const getAppEnvConfig = (): AppEnvConfig => {
    if (env as unknown as EnvMode === EnvMode.dev) {
        return {
            appTitle: 'GPTCN',
            apiHost: 'http://127.0.0.1:3002',
            tokenKey: 'co.gptcn.token',
            kfQrCode: 'kefu.png',
        }
    } else {
        return {
            appTitle: 'GPTCN',
            apiHost: 'https://gptcn.world',
            tokenKey: 'co.gptcn.token',
            kfQrCode: 'kefu.png',
        }
    }
}