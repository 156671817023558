import { Form, Input, Radio } from "antd";
import React from "react";

interface IProps {
}

const AdvertisingCreativity: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='商品名称' name='product'
            rules={[{ required: true, message: '请输入商品名称' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 1 }}
                maxLength={50}
                showCount
                allowClear
                placeholder="请输入商品名称"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='商品特点' name='keywords'
            rules={[{ required: true, message: '请输入商品主要特点' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={100}
                showCount
                allowClear
                placeholder="请输入商品的主要特点"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default AdvertisingCreativity;