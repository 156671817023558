import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const TiktokVideoIdea: React.FC<IProps> = (props: IProps) => {

    return <div>
        <Form.Item label='主题概述' name='target'
            rules={[{ required: true, message: '请输入主题概述' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请描述下你的核心内容"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default TiktokVideoIdea;