import PaymentMenu from './PaymentMenu';
import PromptTemplate from './PromptTemplate';
import UserInfo from './UserInfo';
import Conversation from './Conversation';
import UsageInfo from './UsageInfo';
import AppStoreButton from './AppStoreButton';
import NFTLabsButton from './NFTLabsButton';
import Admin from './Admin'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import { Layout, Modal } from 'antd';
import Chat from './Chat';
import * as Apis from './Apis'
import { ConversationInterface, UsageStatistic, ChatInterface, UserInfoResp } from './Apis'
import { getAppEnvConfig } from './AppConfig'
const { Header, Content } = Layout;

const App: React.FC = () => {

  const navigate = useNavigate();

  const [me, setMe] = useState<UserInfoResp>();
  const [conversationSelected, setConversationSelected] = useState<number>(0);
  const [conversations, setConversations] = useState<ConversationInterface[]>([]);
  const [usageStatistic, setUsageStatistic] = useState<UsageStatistic>();
  const [showExpireModal, setShowExpireModal] = useState<boolean>(false); // state to control whether to show the expiration modal


  useEffect(() => {
    async function checkLoginStatus() {
      const me = await Apis.getUserInfo();
      if (me) {
        setUsageStatistic(me.usage);
        setMe(me);
        if (me.usage?.isExpired) {
          setShowExpireModal(true);
        }
      } else {
        navigate('login');
      }
    }
    checkLoginStatus();
  }, [navigate]);

  useEffect(() => {
    const fetchConversations = async () => {
      const fetchedChatHistory = await Apis.fetchConversations();
      setConversations(fetchedChatHistory);
    };

    fetchConversations();
  }, []);

  const onConversationSelect = async (conversationId: number) => {
    setConversationSelected(conversationId);
    console.log(`conversation selected:`, conversationId)
  }

  const onNewConversationCreated = async (conversation: ConversationInterface) => {
    setConversationSelected(conversation.id);
    console.log(`conversation created:`, conversation);
    // Push the new conversation to the conversations array
    const newCons = [...conversations];
    // Add the new conversation to the beginning of the newCons array
    newCons.unshift(conversation);
    setConversations(newCons)
  }

  // Function to delete a conversation
  const onDeleteConversation = async (conversationId: number) => {
    await Apis.deleteConversation(conversationId);
    setConversations(conversations.filter(conversation => conversation.id !== conversationId));
    if (conversationSelected === conversationId) {
      setConversationSelected(0);
    }
    console.log(`conversation deleted:`, conversationId);
  }

  const onNewChatCreated = async (newChat: ChatInterface) => {
    if (usageStatistic) {
      const newUsageStatistic = { ...usageStatistic };
      newUsageStatistic.todayLeft -= 1;
      setUsageStatistic(newUsageStatistic);
    }
  }

  const isInWhiteList = (phoneNum: string) => {
    // const whiteList = ['18068837532', '18513786798', '18952061098', '18810901587', '18944065240'];
    // return whiteList.includes(phoneNum)
    return true;
  }

  return (
    <Layout style={{ height: '100vh' }}>
      <Header style={{ background: 'black', padding: 0, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', display: 'flex', alignItems: 'center' }}>
        <div style={{ padding: '0 16px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate('/')}>
          {/*   <img src={logo} alt="Brand Logo" style={{height: '32px', marginRight: '16px'}} /> */}
          <span style={{ color: 'white', fontSize: '20px', marginRight: '16px' }}>{getAppEnvConfig().appTitle}</span>
        </div>

        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <NFTLabsButton />
          {(me?.user.phoneNum && isInWhiteList(me.user.phoneNum)) && <PaymentMenu />}
          {/* <PaymentMenu /> */}
          <AppStoreButton />
          {/* <Conversation onConversationSelect={onConversationSelect} conversations={conversations} onConversationDelete={onDeleteConversation} /> */}
          {/*           <PromptTemplate /> */}
          {usageStatistic && <UsageInfo usageStatistic={usageStatistic} />}
          {(me?.user.phoneNum === '18068837532' || me?.user.phoneNum === '18944065240') && <Admin />}
          <UserInfo />
        </div>
      </Header>
      <Content style={{ margin: '0', height: 'calc(100vh - 64)' }}>
        <Chat
          conversationId={conversationSelected}
          onNewConversationCreated={onNewConversationCreated}
          onConversationSelected={onConversationSelect}
          onNewChatCreated={onNewChatCreated}
          conversations={conversations}
          onConversationDelete={onDeleteConversation}
        />
      </Content>

      <Modal
        title="过期提醒"
        open={showExpireModal}
        onCancel={() => setShowExpireModal(false)}
        footer={null}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <p style={{ fontWeight: 'bold' }}>你的使用时间已过期！</p>
        <p>你可以扫码加入用户交流群，群内会不定期发放激活码。使用激活码，可以延长使用期限。</p>
        <img src={getAppEnvConfig().kfQrCode} alt="kefu" style={{ width: '260px' }} />
        <p style={{ marginTop: '20px' }}>如你已获的激活码，可以在<span style={{ fontWeight: 'bold' }}>“信息统计”</span>页面进行使用。</p>
      </Modal>
    </Layout>
  );

}

export default App;