import React, { useEffect, useState, useRef } from 'react';
import { Layout, Input, Button, Avatar, Row, Col, Typography, Skeleton, message, notification, Modal, Space } from 'antd';
import { SendOutlined, MessageOutlined, UserOutlined, RobotFilled, UnorderedListOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import * as Apis from './Apis';
import { ChatInterface, ConversationInterface, AppScene } from './Apis'
import { getAppEnvConfig } from './AppConfig'
import Conversation from './Conversation'
import { isMobile } from 'react-device-detect'
import { nanoid } from 'nanoid'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const { Content } = Layout;

interface IProps {
    conversationId: number
    onConversationSelected: (conversationId: number) => void
    onNewConversationCreated: (conversation: ConversationInterface) => void
    onNewChatCreated: (chat: ChatInterface) => void

    conversations: ConversationInterface[]
    onConversationDelete: (conversationId: number) => void
}

const Chat: React.FC<IProps> = (props) => {

    const [chats, setChats] = useState<ChatInterface[]>([]);
    const [newPrompt, setNewPrompt] = useState<string>('');
    const [isAsking, setIsAsking] = useState<boolean>(false);

    const chatContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chats]);

    // Fetch conversation detail with conversationId
    useEffect(() => {
        const fetchConversationDetail = async () => {
            try {
                const response = await Apis.fetchConversationDetail(props.conversationId);
                console.log(`res:`, response)
                setChats(response as ChatInterface[] || []);
                // Update messageHistory with the fetched data
            } catch (error) {
                console.error("Error fetching conversation detail:", error);
            }
        };

        if (props.conversationId > 0) {
            fetchConversationDetail();
        } else {
            setChats([]);
        }
    }, [props.conversationId]);

    // Handle new conversation button click
    const handleNewConversationClick = () => {
        // createNewConversation();
        props.onConversationSelected(0);
    };

    const createChat = async () => {
        console.log(`conversationId:`, props.conversationId)

        if (newPrompt) {
            const tmpChat: ChatInterface = {
                id: -1,
                conversationId: props.conversationId,
                prompt: newPrompt,
                completion: '正在生成内容，请稍后...',
            }

            const newTmpChats = [...chats];
            newTmpChats.push(tmpChat);
            setChats(newTmpChats);

            setNewPrompt('');
            setIsAsking(true);

            try {
                const response = await Apis.createNewChat(newPrompt, AppScene.Chat, props.conversationId);
                console.log(`new chat response:`, response)
                if (response) {
                    const length = newTmpChats.length;
                    if (newTmpChats[length - 1].id === -1) {
                        newTmpChats.pop();
                    }
                    newTmpChats.push(response as ChatInterface)
                    setChats(newTmpChats)

                    props.onNewChatCreated(response as ChatInterface)

                    if ((response as ChatInterface).newConversation) {
                        const conv = (response as ChatInterface).newConversation;
                        if (conv) {
                            props.onNewConversationCreated(conv)
                        }
                    }
                } else {
                    setNewPrompt(newPrompt);
                    const length = newTmpChats.length;
                    if (newTmpChats[length - 1].id === -1) {
                        newTmpChats.pop();
                        setChats(newTmpChats);
                    }
                    message.error(`生成内容失败，请重试...`);
                }
            } catch (e) {
                const length = newTmpChats.length;
                if (newTmpChats[length - 1].id === -1) {
                    newTmpChats.pop();
                    setChats(newTmpChats);
                }
                setNewPrompt(newPrompt);
                console.error(e)

                if (e === '40001') {
                    message.error(`今日使用次数已用完`)
                    notification.error({
                        message: '使用提醒',
                        description: '今日使用次数已用完，请联系客服进行购买',
                        duration: 15,
                    })
                } else if (e === `40002`) {
                    // notification.error({
                    //     message: '过期提醒',
                    //     description: '账号使用权限已过期，请联系客服进行购买',
                    //     duration: 15,
                    // })
                    Modal.error({
                        title: '过期提醒',
                        content: <div>
                            <p>你的使用时间已过期！你可以扫码加入用户交流群，群内会不定期发放激活码。</p>
                            <p>使用激活码，可以延长使用期限。如你已获的激活码，可在<span style={{ fontWeight: 'bold' }}>“信息统计”</span>页面进行使用。</p>
                            <img src={getAppEnvConfig().kfQrCode} alt="kefu" style={{ width: '260px' }} />
                        </div>,
                        okText: '确定',
                        okButtonProps: {
                            block: true,
                        },
                    })
                } else {
                    notification.error({
                        message: '失败提醒',
                        description: '生成内容失败，请重试...',
                    })
                }
            } finally {
                setIsAsking(false)
            }
        }
    }

    const renderPrompt = (prompt: string) => {
        return renderCompletion(prompt);
    }

    const renderCompletion = (completion: string) => {
        // const data = completion.split('\n');
        return <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
                code: ({ node, inline, className, children, ...props }) => {
                    const match = /language-(\w+)/.exec(className || '');
                    return !inline && match ? (
                        <pre
                            style={{ backgroundColor: 'black', padding: '16px', borderRadius: '8px' }}
                            {...props}
                        >
                            <code className={`language-${match[1]}`} style={{ color: 'white' }}>
                                {children}
                            </code>
                        </pre>
                    ) : (
                        <code className={className} {...props}>{children}</code>
                    );
                },
            }}
        >
            {completion}
        </ReactMarkdown>
    }

    const onPromptChange = (val: string) => {
        setNewPrompt(val);
    }

    const renderGenerating = () => {
        return <Skeleton active paragraph={{ rows: 1, width: 300 }} title={true} avatar={false} />
    }

    const generateChatContent = () => {
        if (chats.length > 0) {
            return chats.map((chat, index) => (
                <div key={chat.id}>
                    <div style={{ display: 'flex', alignItems: 'start', marginBottom: '16px', justifyContent: 'flex-end' }}>
                        <div
                            style={{
                                background: 'linear-gradient(to right, #2770ea, #1b4aef)',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                color: 'white',
                                overflowX: 'auto',
                                marginLeft: isMobile ? '20px' : '50px', minHeight: '40px'
                            }}>
                            {renderPrompt(chat.prompt)}
                        </div>
                        {!isMobile && <div style={{ marginLeft: '10px' }}>
                            <Avatar icon={<UserOutlined />} size={40} />
                        </div>}

                    </div>
                    <div style={{ display: 'flex', alignItems: 'start', marginBottom: '16px' }}>
                        {!isMobile && <div style={{ marginRight: '10px' }}>
                            <Avatar icon={<RobotFilled />} size={40} />
                        </div>}
                        <div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                padding: '8px 12px',
                                color: 'black',
                                overflowX: 'auto',
                                marginRight: isMobile ? '20px' : '50px',
                                minHeight: '40px'
                            }}>
                            {chat.id > 0 ?
                                renderCompletion(chat.completion) :
                                renderGenerating()
                            }
                        </div>
                    </div>
                </div>
            ))
        } else {
            return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '2em' }}>
                <span>Chat</span>
                <span>😀 💬 🤖</span>
            </div>
        }
    }

    return (
        <Layout style={{ height: '100%', flexDirection: 'column', alignItems: 'center', backgroundColor: '#eff1fa' }}>
            <Row style={{ width: '100%', height: '100%' }}>
                <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}
                    style={{ width: '100%', height: '100%', padding: '0' }} >
                    <Content ref={chatContainerRef} style={{ height: '100%', padding: '30px 16px 100px 16px', overflowY: 'auto' }}>
                        {generateChatContent()}
                    </Content>
                </Col>
            </Row>

            <Row style={{ width: '100%' }}>
                <Col xs={{ offset: 0, span: 24 }} sm={{ offset: 2, span: 20 }} md={{ offset: 2, span: 20 }} lg={{ offset: 4, span: 16 }}
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #f0f0f0',
                        borderRadius: '16px 16px 0 0',
                        padding: '12px 8px',
                        width: '100%',
                        position: 'fixed',
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >

                    {/* {isMobile ?
                        <Button icon={<PlusOutlined />} type={'default'} shape='circle' onClick={e => handleNewConversationClick()} /> :
                        <Button icon={<PlusOutlined />} type={'default'} shape='round' onClick={e => handleNewConversationClick()}>新对话</Button>
                    } */}

                    <div style={{ display: 'flex', alignItems: 'center', borderRadius: '8px', background: 'white', border: '1px solid #f0f0f0', padding: '0 12px' }}>
                        {/* <Button type='text' style={{ marginRight: '10px' }} icon={<UnorderedListOutlined />}></Button> */}
                        <Conversation onConversationSelect={props.onConversationSelected} conversations={props.conversations} onConversationDelete={props.onConversationDelete} />
                        <Button type='text' icon={<PlusCircleOutlined />} onClick={e => handleNewConversationClick()}></Button>
                    </div>

                    <div style={{ height: '32px', width: '1px', backgroundColor: '#f0f0f0', margin: '0 10px' }}></div>
                    <Input.TextArea
                        maxLength={1000}
                        placeholder="请输入内容"
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onChange={e => onPromptChange(e.target.value)}
                        value={newPrompt}
                        bordered={false}
                        style={{ padding: '0 10px 0 0' }}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                createChat();
                            }
                        }}
                    />

                    <Button icon={<SendOutlined />} type={'text'} shape='round' onClick={e => createChat()} disabled={isAsking} loading={isAsking}></Button>
                </Col>
            </Row>
        </Layout>
    );
};

export default Chat;