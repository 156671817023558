import React, {useState, useEffect} from 'react';
import { Modal, Button, Avatar, Dropdown, Menu, MenuProps, Drawer, Divider, Space, Input, message, Image } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import {UsageStatistic, formatSubscribeVersionToTitle, getUserInfo, consumeActiveCode} from './Apis';
import {getAppEnvConfig} from './AppConfig'
import {isMobile} from 'react-device-detect'

interface IProps {
  usageStatistic: UsageStatistic
}

const UsageInfo: React.FC<IProps> = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activationCode, setActivationCode] = useState(""); 
    const [loading, setLoading] = useState<boolean>(false);

    // When the user types in the input field, we update the activationCode state
    const handleActivationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setActivationCode(event.target.value);
    };

    // When the user clicks the "激活" button, we can use the activationCode state to activate the code
    const handleActivate = async () => {
      if (activationCode) {
        setLoading(true);
        try {
          await consumeActiveCode(activationCode);
          setActivationCode('');
          message.info(`激活成功`);
        } catch (e) {
          message.error(`error: ${e}`);
        } finally {
          setLoading(false)
        }
      } else {
        message.error(`请输入激活码`);
      }
    };

    const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
      <>
      {isMobile ? 
      <Button type="text" style={{color: 'white', width: 46}} onClick={showModal} icon={<InfoCircleOutlined />} /> :
      <Button type="text" style={{color: 'white'}} onClick={showModal} icon={<InfoCircleOutlined />}>信息统计</Button>
      }
      <Drawer
          title="信息统计"
          open={isModalVisible}
          onClose={handleCancel}
          bodyStyle={{overflowY: 'auto', padding: 20}}
        >
        {props.usageStatistic &&
        <>
        <p>🎮 版本类型：{formatSubscribeVersionToTitle(props.usageStatistic.version)}</p>
        <p>⏰ 到期日：{props.usageStatistic.expireAt}</p>
        <p>🚀 当日剩余次数：{props.usageStatistic.todayLeft} 次</p>
        </>
        }

        <Divider />
        
        <Space direction='vertical' style={{width: '100%'}}>
          <span style={{fontWeight: 'bold'}}>使用激活码</span>
          <Input placeholder='请输入激活码' value={activationCode} onChange={handleActivationCodeChange}></Input>
          <Button type='primary' style={{width: '100px'}} onClick={handleActivate} loading={loading} disabled={loading || activationCode === ''}>激活</Button>
          <Divider />
          <span style={{ fontWeight: 'bold' }}>问题咨询，请添加客服微信进行沟通</span>
          <Image src={getAppEnvConfig().kfQrCode} width={200} />
        </Space>
      </Drawer>
      </>
  );
};

export default UsageInfo;