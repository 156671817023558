import { Form, Input, InputNumber } from "antd";
import React from "react";

interface IProps {
}

const CompanySlogan: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='业务/产品/服务描述' name='service'
            rules={[{ required: true, message: '请输入业务描述' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入业务产品，可以是产品信息、服务信息等"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='Slogan 条数' name='count'
            rules={[{ required: true, message: '请输入条数' }]}
        >
            <InputNumber
                min={1}
                max={5}
                placeholder="需要生成几条 slogan，最多 5 条"
                addonAfter={<span>条</span>}
                style={{width: '100%'}}
            ></InputNumber>
        </Form.Item>
    </div>
}

export default CompanySlogan;