import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const XHSNoteTitle: React.FC<IProps> = (props: IProps) => {

    return <div>
        <Form.Item label='商品名称' name='contentDesc'
            rules={[{ required: true, message: '请输入商品名称' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={50}
                showCount
                allowClear
                placeholder="请输入商品名称"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='商品关键特点' name='keywords'
            rules={[{ required: true, message: '请输入商品关键特点' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入商品关键特点"
            ></Input.TextArea>
        </Form.Item>
    </div>
}

export default XHSNoteTitle;