export enum CategoryId {
    All = 1,
    Promotion = 2,
    Office = 3,
    Write = 4,
    MBA = 5,
}

export interface Category {
    id: CategoryId
    title: string
}

export interface ChatApp {
    icon: string
    appId: string
    title: string
    desc?: string
    categoryId: number
}

export enum ChatAppId {
    TiktokVideoScript = 'U4OrINEBxu8lNoFCGntju',
    TiktokVideoBroadcast = '53bIuWrch3yjvCpb5hTdr',
    TiktokVideoIdea = 'p8APe4Nt4WhorgvcA7bpp',
    HHSCopywritting = '7UeIBcUTFmTEHCASRR5kD',
    HSSTitle = 'FYP6RMKtDoFLmovkgBZNk',
    ContentRewriting = '2uwvn3gzv1eqxhJplQOXt',
    ArticleIdea = 'rnnae-YXbzzLRqwW18cq8',
    ArticleOutline = '08PFIdRSM_GhYV_egJSS1',
    ProductIntro = 't2B46cN2u1H6wDOFI5R69',
    ProductSellingPoint = 'KOZqNqdpVQ6Pl737Yw6ST',
    OfficeEmail = 'BOafr1iNHnyesQybtqzd2',
    OfficeWeeklyReport = 'yB36LAEeCcC10dXpQTRoE',
    TranslateFromChineseToEnglish = 'AcusOGI01vFeDuHbVeRCt',
    TranslateFromEnglishToChinese = 'yBibkLEQLSQl2QWbr6xWw',
    Translation = 'PDDjNIuWKxay93druoTF2',
    AdvertisingCreativity = 'qKtTxzY6X9SsS8eAETPTX',
    EventPlan = 'pxT407KoIampiqQwdgY8x',
    Announcement = 'annoncement',
    ArticleWrite = 'article-write',
    FiveForces = 'five-force',
    ValueNet = 'value-net',
    SWOT = 'swot',
    ContentSummary = 'content-summary',
    CompanySlogan = 'company-slogan',
}

export const chatApps: ChatApp[] = [
    {
    icon: '📹',
    appId: ChatAppId.TiktokVideoScript,
    title: '短视频脚本',
    desc: '创意短视频脚本，让你的内容吸引眼球',
    categoryId: CategoryId.Promotion,
}, 
// {
//     icon: '🎙️',
//     appId: ChatAppId.TiktokVideoBroadcast,
//     title: '短视频口播',
//     desc: '精彩短视频口播，让你在镜头前脱颖而出',
//     categoryId: CategoryId.Promotion,
// }, {
//     icon: '🚀',
//     appId: ChatAppId.TiktokVideoIdea,
//     title: '短视频创意',
//     desc: '激发创作潜能，助你成为短视频达人',
//     categoryId: CategoryId.Promotion,
// }, 
// {
//     icon: '🥳',
//     appId: ChatAppId.HHSCopywritting,
//     title: '小红书文案',
//     desc: '轻松写出小红书文案、生成文案创意',
//     categoryId: CategoryId.Promotion,
// },
{
    icon: '✍️',
    appId: ChatAppId.ContentRewriting,
    title: '内容改写',
    desc: '让你的创作焕然一新，吸引更多关注与喜爱',
    categoryId: CategoryId.Write,
},
// {
//     icon: '💡',
//     appId: ChatAppId.ArticleIdea,
//     title: '内容创意',
//     desc: '激发灵感火花，为你的作品增添独特魅力',
//     categoryId: CategoryId.Promotion,
// }, 
{
    icon: '✍️',
    appId: ChatAppId.ArticleWrite,
    title: '写作',
    desc: '根据要求编写文章内容',
    categoryId: CategoryId.Write,
}, {
    icon: '📖',
    appId: ChatAppId.ArticleOutline,
    title: '文章大纲',
    desc: '让你的写作结构清晰，思路明朗，一气呵成',
    categoryId: CategoryId.Write,
}, {
    icon: '🛒',
    appId: ChatAppId.ProductIntro,
    title: '产品介绍',
    desc: '产品介绍巧妙之道，展现产品魅力',
    categoryId: CategoryId.Promotion,
}, {
    icon: '🔥',
    appId: ChatAppId.ProductSellingPoint,
    title: '产品卖点',
    desc: '挖掘产品卖点，打造独特优势，让产品脱颖而出',
    categoryId: CategoryId.Promotion,
},
{
    icon: '📧',
    appId: ChatAppId.OfficeEmail,
    title: '撰写邮件',
    desc: '高效邮件写作技巧，提升沟通效率，展现专业魅力',
    categoryId: CategoryId.Office,
},
{
    icon: '🔠',
    appId: ChatAppId.Translation,
    title: '语言翻译',
    desc: '将输入的内容翻译成其他语言',
    categoryId: CategoryId.Office,
},
// {
//     icon: '🪧',
//     appId: ChatAppId.AdvertisingCreativity,
//     title: '广告创意',
//     desc: '广告创意策略，激发品牌潜能',
//     categoryId: CategoryId.Promotion,
// }, 
{
    icon: '🤔',
    appId: ChatAppId.FiveForces,
    title: '五力模型',
    desc: '使用五力模型来做商业分析',
    categoryId: CategoryId.MBA,
}, {
    icon: '🤔',
    appId: ChatAppId.SWOT,
    title: 'SWOT',
    desc: '使用 SWOT 模型来做商业分析',
    categoryId: CategoryId.MBA,
}, {
    icon: '🌐',
    appId: ChatAppId.ValueNet,
    title: '价值网络',
    desc: '使用 Value Net 模型来做商业分析',
    categoryId: CategoryId.MBA,
}, {
    icon: '🔖',
    appId: ChatAppId.ContentSummary,
    title: '内容摘要',
    desc: '概括总结给定的内容，生成内容摘要信息',
    categoryId: CategoryId.Office,
}, {
    icon: '🔥',
    appId: ChatAppId.CompanySlogan,
    title: 'Slogan',
    desc: '为产品、服务、公司等生成 Slogan',
    categoryId: CategoryId.Office,
},
];

export function getChatAppsByCategoryId(categoryId: CategoryId): ChatApp[] {
    if (categoryId === CategoryId.All) {
        return chatApps;
    } else {
        return chatApps.filter((chatApp) => chatApp.categoryId === categoryId);
    }
}

export interface ParamsTiktok {
    target: string
    keywords: string
}

export interface ParamsHHSCopyWriting {
    productName: string
    productFeatures: string
}

export interface ParamsHHSNoteTitle {
    contentDesc: string
    keywords: string
}

export interface ParamsContentRewriting {
    originContent: string
    rewriteRequirement: string
}

export interface ParamsArticleOutline {
    articleTopic: string
    view: string
    audience: string
    category: string
}

export interface ParamsArticleIdea {
    topic: string
    audience: string
}

interface ParamsProductIntro {
    productName: string
    productFeature: string
}

interface ParamsProductSellingPoint {
    productName: string
    productDesc: string
}

interface ParamsOfficeEmail {
    receiver: string
    topic: string
    style: string
}

interface ParamsTranslation {
    content: string
    toLanguage: string
}

interface ParamsAdvertisingCreativity {
    product: string
    keywords: string
}

interface ParamsEventPlan {
    topic: string
}

interface ParamsArticleWrite {
    topic: string
    keyView: string
    audience: string
    style: string
}

interface ParamsMBASWOT {
    industry: string
    biz: string
    basicInfo: string
    count: number
}

interface ParamsMBAValueNet {
    industry: string
    biz: string
    basicInfo: string
}

interface ParamsMBAFiveForces {
    industry: string
    biz: string
    basicInfo: string
}

interface ParamsContentSummary {
    content: string
    requirement: string
}

interface ParamsCompanySlogan {
    service: string
    count: number
}

export const formatLanguageStyle = (style: number): string => {
    if (style === 1) {
        return `不限`;
    } else if (style === 2) {
        return `正式`;
    } else if (style === 3) {
        return `专业`;
    } else if (style === 4) {
        return `幽默`;
    } else if (style === 5) {
        return `热情`;
    } else {
        return `不限`;
    }
}

export const generatePromptForChatApp = (appId: string, params: {}): string => {
    let p;

    switch (appId) {
        case ChatAppId.TiktokVideoScript:
            p = params as ParamsTiktok;
            return `Q: 请写抖音风格的短视频拍摄脚本。主题为${p.target}，关键词为${p.keywords}\nA: `;
        case ChatAppId.TiktokVideoBroadcast:
            p = params as ParamsTiktok;
            return `Q: 请写抖音风格的口播内容，主题为${p.target}，关键词为${p.keywords}，在结尾部分增加明确的行动召唤\nA: `;
        case ChatAppId.TiktokVideoIdea:
            p = params as ParamsTiktok;
            return `Q: 你现在是短视频创意人员，请根据我的描述信息，为我想 5 条视频创意。我的描述信息是：${p.target}\nA: `;
        // return `请写抖音风格的标题。内容为${p.target}`;
        case ChatAppId.HHSCopywritting:
            p = params as ParamsHHSCopyWriting;
            return `请为${p.productName}写一篇小红书风格的种草文案，商品特点为${p.productFeatures}`;
        case ChatAppId.HSSTitle:
            p = params as ParamsHHSNoteTitle;
            return `
                Context:
                小红书笔记标题的特点是：
                1. 引起读者兴趣：标题通常使用感叹词、问号或表情符号等形式，用生动的方式引起读者的好奇心。
                2. 强调美食的诱惑：标题会用一些形容词或形容性的词语来描述食物的特点，让读者在看到标题时就能感受到美食的诱惑。
                3. 突出个人体验：标题通常用第一人称来表达作者的体验和感受，从而更贴近读者的生活场景和感受。
                4. 突出实用性：标题往往突出实用性，强调笔记的实用价值，使读者更愿意点击和分享。

                Q：请按照上述特点，为我写 5 个标题，产品是${p.contentDesc}，产品的特点是${p.keywords}，
                字数在 25 字以内，标题里要有 emoji，emoji 要放在标题里面，不要在标题前和标题最后
                `;
        case ChatAppId.ContentRewriting:
            p = params as ParamsContentRewriting;
            return `请改写下面的内容:\n\n需要改写的内容: ${p.originContent}\n改写要求: ${p.rewriteRequirement}`;
        case ChatAppId.ArticleIdea:
            p = params as ParamsArticleIdea;
            return `你现在是文章写作创意咨询师，按照下面的要求给我 10 条创意: \n\n写作主题: ${p.topic}\n文章受众: ${p.audience}`;
        case ChatAppId.ArticleOutline:
            p = params as ParamsArticleOutline;
            return `写一份文章的大纲，要求如下:\n\n
                    文章主题: ${p.articleTopic}\n
                    文章核心观点: ${p.view}\n
                    文章受众: ${p.audience}\n
                    文章类型: ${p.category}`;
        case ChatAppId.ProductIntro:
            p = params as ParamsProductIntro;
            return `我将向你提供一个产品的名称和这个产品的特点，请你根据这些内容扩写一份关于这个产品的介绍。\n
                    Q: 我给你的产品名称是${p.productName}，产品特点是${p.productFeature}\n
                    A: `;

        case ChatAppId.ProductSellingPoint:
            p = params as ParamsProductSellingPoint;
            return `我将向你提供一个产品的名称，和与这个产品相关的一些信息，请帮我罗列这款产品的特点，要求尽量简洁、有吸引力。\n
                                输出的形式是1. 特点1；2. 特点2；以此类推。\n
                                现在，我给到的产品名称是：${p.productName}。产品信息是：${p.productDesc}`;

        case ChatAppId.OfficeEmail:
            p = params as ParamsOfficeEmail;
            return `Q: 请为我写一封邮件，收件人是：${p.receiver}，邮件的主题是：${p.topic}，邮件正文的风格是：${p.style} \nA: `;

        case ChatAppId.Translation:
            p = params as ParamsTranslation;
            return `你是一名专业的翻译人员，请将我给出的内容翻译成${p.toLanguage}。Q: ${p.content}\n A: `;
        case ChatAppId.TranslateFromEnglishToChinese:
            p = params as ParamsTranslation;
            return `你是一名专业的翻译人员，请将我给出的英文内容翻译成中文。Q: ${p.content}\n A: `;

        case ChatAppId.AdvertisingCreativity:
            p = params as ParamsAdvertisingCreativity;
            return `你是一名广告创意人员，请根据我的主题，为我生成一条广告创意，要包含广告语和创意描述。\n
            Q: 商品是：${p.product}，它的特点是：${p.keywords}\n
            A: `;

        case ChatAppId.EventPlan:
            p = params as ParamsEventPlan;
            return `你是一个活动策划人员，请根据我的要求，为我写一份活动策划方案，只需要包含活动内容和活动目的。\n
            Q: 活动的主题是: ${p.topic}\n
            A: `;
        case ChatAppId.ArticleWrite:
            p = params as ParamsArticleWrite;
            return `你是一名作家，下面是一篇文章的要求信息：\n
            文章主题：${p.topic}
            文章核心观点：${p.keyView}
            文章的受众信息：${p.audience}
            文章的写作风格：${p.style}

            Q: 请根据上面的要求，为我写一篇文章
            A:
            `
        case ChatAppId.SWOT:
            p = params as ParamsMBASWOT;
            return `你是一名商业咨询师，请根据以下的信息，为我公司生成 SWOT 分析报告：\n\n
                        公司的行业是：${p.industry}\n
                        公司的业务是：${p.biz}\n
                        公司基本信息是：${p.basicInfo}\n\n
    
                        生成的 SWOT 报告，每个维度 ${p.count} 条。\n
                        `

        case ChatAppId.ValueNet:
            p = params as ParamsMBAValueNet;
            return `你是一名商业咨询顾问，请使用商业策略中的 Value Net 模型，对我公司进行分析，公司的基本信息如下：\n\n
                        行业：${p.industry}\n
                        业务范围：${p.biz}\n
                        公司描述：${p.basicInfo}。\n`

        case ChatAppId.FiveForces:
            p = params as ParamsMBAFiveForces;
            return `你是一名商业分析师，请使用五力模型，对我公司进行分析，公司的信息如下：\n\n
                        行业：${p.industry}\n
                        业务范围：${p.biz}\n
                        公司描述：${p.basicInfo}。\n`

        case ChatAppId.ContentSummary:
            p = params as ParamsContentSummary;
            if (p.requirement) {
                return `有一段内容如下：\n${p.content}\n\nQ: 请给我总结下这段内容的摘要，要求：${p.requirement}\nA:`
            } else {
                return `有一段内容如下：\n${p.content}\n\nQ: 请给我总结下这段内容的摘要\nA:`
            }

        case ChatAppId.CompanySlogan:
            p = params as ParamsCompanySlogan;
            return `信息如下\n${p.service}\nQ: 以上是我的信息，请为我生成 ${p.count} 条 Slogan\nA:`
        default:
            return ``;
    }
}