import { Form, Input } from "antd";
import React from "react";

interface IProps {

}

const ContentRewriting: React.FC<IProps> = (props: IProps) => {

    return <div>
        <Form.Item label='需要改写的内容' name='originContent'
            rules={[{ required: true, message: '请输入待改写内容' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 20 }}
                maxLength={600}
                showCount
                allowClear
                placeholder="请输入需要改写的内容"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='改写要求' name='rewriteRequirement'
            rules={[{ required: true, message: '请输入改写要求' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxLength={400}
                showCount
                allowClear
                placeholder="请输入改写要求"
            ></Input.TextArea>
        </Form.Item>

        {/* <Form.Item label='语言风格' name='languageStyle'
            rules={[{ required: true, message: '请选择语言风格' }]}
        >
            <Select>
                <Select.Option value={1}>不限</Select.Option>
                <Select.Option value={2}>正式</Select.Option>
                <Select.Option value={3}>专业</Select.Option>
                <Select.Option value={4}>幽默</Select.Option>
                <Select.Option value={5}>热情</Select.Option>
            </Select>
        </Form.Item> */}
{/* 
        <Form.Item label='输出语言' name='toLanguage'
            rules={[{ required: true, message: '请选择输出语言类型' }]}
        >
            <Radio.Group>
                <Radio value={1}>中文</Radio>
                <Radio value={2}>英文</Radio>
            </Radio.Group>
        </Form.Item> */}
    </div>
}

export default ContentRewriting;