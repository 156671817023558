import React, { useState, useEffect } from 'react';
import { Button, Drawer, message } from 'antd';
import {MenuFoldOutlined, DeleteOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import * as Apis from './Apis'
import {ConversationInterface, ChatInterface} from './Apis'
import {isMobile} from 'react-device-detect'

interface IProps {
  conversations: ConversationInterface[]
  onConversationSelect: (conversationId: number) => void
  onConversationDelete: (conversationId: number) => void
}

const Conversation: React.FC<IProps> = (props) => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


// Click function to handle chat item click
const onChatItemClick = (chat: ConversationInterface) => {
  // Perform desired actions on chat item click
  // message.info(`click: ${chat.id}`)
  setVisible(false);
  props.onConversationSelect(chat.id);
};

  return (
    <>
      {/* {isMobile ?
      <Button type="text" onClick={showDrawer} style={{color: 'white', width: 46}} icon={<MenuUnfoldOutlined style={{color: 'white'}} />} /> :
    <Button type="text" onClick={showDrawer} style={{color: 'white'}} icon={<MenuUnfoldOutlined style={{color: 'white'}} />}>
    历史会话
  </Button>  
    } */}
    <Button type='text' onClick={showDrawer} style={{ marginRight: '10px' }} icon={<MenuUnfoldOutlined />}></Button>
      <Drawer
        title="历史会话"
        placement="right"
        closable
        onClose={onClose}
        open={visible}
      >
          <div style={{ height: 'calc(100vh - 55px)' }}>
          {props.conversations.map((chat, index) => (
            <div key={index} style={{ marginBottom: "10px", color: 'black', border: '1px solid #f5f5f5', borderRadius: '6px', padding: '12px 16px' }}>
              <span style={{cursor: 'pointer'}} onClick={() => onChatItemClick(chat)}>{chat.title}</span>
              <span style={{ float: 'right' }}>
                <DeleteOutlined onClick={e => props.onConversationDelete(chat.id)} />
              </span>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
};

export default Conversation;