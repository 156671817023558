import React from 'react';
import { Button } from 'antd';
import {AppstoreOutlined} from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import {useNavigate} from 'react-router-dom';

const AppStoreButton = () => {
    const navigate = useNavigate();

    const toAppStorePage = () => {
        navigate('apps')
    }

    return (
        <>
            {isMobile ?
                <Button type="text" onClick={toAppStorePage} style={{ color: 'white', width: 46 }} icon={<AppstoreOutlined style={{ color: 'white' }} />} /> :
                <Button type="text" onClick={toAppStorePage} style={{ color: 'white' }} icon={<AppstoreOutlined style={{ color: 'white' }} />}>
                    应用
                </Button>
            }
        </>
    );
};

export default AppStoreButton;