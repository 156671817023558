import { Form, Input } from "antd";
import React from "react";

interface IProps {
}

const ArticleWrite: React.FC<IProps> = (props: IProps) => {
    return <div>
        <Form.Item label='主题' name='topic'
            rules={[{ required: true, message: '请输入写作的主题' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 4 }}
                maxLength={200}
                showCount
                allowClear
                placeholder="请输入写作的主题"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='核心观点' name='keyView'
            rules={[{ required: true, message: '请输入文章的核心观点' }]}
        >
            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 6 }}
                maxLength={500}
                showCount
                allowClear
                placeholder="请输入文章的核心观点"
            ></Input.TextArea>
        </Form.Item>

        <Form.Item label='文章受众' name='audience'
            rules={[{ required: true, message: '请输入文章受众描述' }]}
        >
            <Input
                maxLength={100}
                allowClear
                placeholder="请输入文章的受众信息"
            ></Input>
        </Form.Item>

        <Form.Item label='文章风格' name='style'
            rules={[{ required: true, message: '请输入文章风格' }]}
        >
            <Input
                maxLength={100}
                allowClear
                placeholder="请输入文章的风格信息，如正式、严肃、通俗易懂"
            ></Input>
        </Form.Item>
    </div>
}

export default ArticleWrite;